import  qs from "qs";
/**
 * Class for handling alert REST request
 */
class Subscribe {
    /**
     * Initialize class
     * @param {object} axios Axios instance
     */
    constructor(axios, location) {
        this.axios = axios
        this.location = location
    }
    
    /**
     * TODO replace with none mocked data
     */
    async Join(data) {
        var config = {
            method: 'post',
            url: this.location+'/jointheclub',
            headers: { 
                'Content-Type': 'application/json'
            },
            data : JSON.stringify(data)
        };
          
        let response  = await this.axios(config)

        return response
    }

    async FollowSpotify(accessToken) {
        // follow Kayeandre
        let artist_params = { type:'artist', ids:'5KMWoOycFFU0g6AL2xHITZ' };
        let artist_string = qs.stringify( artist_params);
        let config = {
            method: "put",
            url: "https://api.spotify.com/v1/me/following?" + artist_string,
            headers: {
              'Authorization': 'Bearer ' + accessToken,
            }
        };
        return await this.axios(config)
    }
}

export default Subscribe