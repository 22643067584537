
import Subscribe from "./subscribe"
/**
 * REST request router 
 */
class Router {
    /**
     * Initialize request router 
     * @param {object} axios Axios instance
     */
    constructor(axios, location) {
        this.Subscribe = new Subscribe(axios, location)  
    }
}

export default Router