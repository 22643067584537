import React, { useCallback, useMemo, useState,useEffect } from "react"
import { withRouter } from "./withRouter"
import { Canvas, useFrame, useThree } from "react-three-fiber"
import { Button, Header, Menu, Placeholder, Transition, Image as Img, Form,List } from "semantic-ui-react"
import { OrbitControls, Stars, PerspectiveCamera, Sky } from "drei"
import Page from "./components/Page"
import Song from "./redemption"
import Follow from "./follow"
import AnimationCanvas from "./components/AnimationCanvas"
import ApiManager from "./api/ApiManager"

const location = 'http://54.162.38.116:8080'
class Spotify extends React.Component {
    
    constructor(props){
        super(props)

        let returned_url = props.location.hash.substr(1);
        let hash = returned_url.split("&");
        let accessToken

        if (returned_url!==""&&returned_url!==undefined) {
            accessToken = hash[0].split("=")[1] || 0
        }

        console.log(accessToken)
        this.state = { song: Song(), img: false, email: '', joined: false, accessToken: accessToken}
    }
    componentDidMount= async()=>{
        
        const loadImage = (image) => {
            return new Promise((resolve, reject) => {
              const loadImg = new Image()
              loadImg.src = image
              // wait 2 seconds to simulate loading time
              loadImg.onload = () =>
                setTimeout(() => {
                  resolve(image.url)
                }, 2000)
      
              loadImg.onerror = err => reject(err)
            })
        }
        await loadImage(this.state.song.coverArt)
        .then(() => this.setState({img:true}))
        .catch(err => console.log("Failed to load images", err))
        if(this.state.accessToken!==undefined) {
            let rs= await ApiManager.router.Subscribe.FollowSpotify(this.state.accessToken)
        }
    }
    joinTheClub = async() => {
        if(this.state.email==='') {
            this.setState({joined: "failed"})
            return 
        }
        let res
        if (/^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/.test(this.state.email))
        {
            res = await ApiManager.router.Subscribe.Join({email: this.state.email})
            if(res.status===200) {
                this.setState({joined: true})
            }
            else {
                this.setState({joined: "failed"})
                return
            }
        }
        else {
            this.setState({joined: "failed"})
            return
        }
        
    }
    handleChange = (e, {name, value}) => {
        this.setState({[name]:value})
    }
    
  
      
render() {
    const items = [
        { key: 'music', name: 'MUSIC' },
        { key: 'gallery', name: 'GALLERY' },
        { key: 'clubpym', name: 'CLUB PYM' }
    ]
    
    let background = (offsetY)=>{
        let translation =  `translateY(${offsetY * 0.05}px)`
        let translation2 =  `translateY(${offsetY * 0.1}px)`
        /**
         * 
         * <div style={{position: "absolute", height: "100%", width: "100%"}}>
                <Image src={planet1} width={50} style={{position: "absolute",left: "20%", top: "10%", transform: translation}}></Image>
                <Image src={planet2} width={100} style={{position: "absolute",left: "50%", top: "20%", transform: translation2}}></Image>
                <Image src={planet3} width={150} style={{position: "absolute", left: "10%", top: "50%", transform: translation}}></Image>
                <Image src={moon1} width={50} style={{position: "absolute", left: "70%", top: "60%", transform: translation2}}></Image>
            </div>
         */
        return(
        <>
            <AnimationCanvas offsetY={offsetY}/>
        </>
    )}

    let songBlockContent = (props) => (
        <>  {(this.state.song!==undefined)&&
            <div className='newSongContent'>
                <Placeholder style={{ height: "auto", maxHeight: "300px", maxWidth: "300px"}}>
                    <Img src={this.state.song.coverArt} style={{ height: "auto", width: "100%", maxHeight: "300px", maxWidth: "300px"}}/>
                </Placeholder>
                <Header>{this.state.song.name.toUpperCase()}</Header>
                <p>PROD. BY {this.state.song.producedBy.toUpperCase()}</p>
                {/* {(!this.state.accessToken)?
                    <Button secondary as='a' href={location+"/login"}><i className="fab fa-spotify"></i>{"  "}FOLLOW ON SPOTIFY</Button>
                    :
                    <Button disabled secondary as='a'><i className="fab fa-spotify"></i>{"  "}FOLLOWED</Button>
                } */}
                <List className="musicPlatforms">
                    {this.state.song.platforms.map((platform)=>{
                        return(
                            <List.Item key={platform.name}>
                                <div>{platform.name.toUpperCase()}</div>
                                <Button secondary as='a' href={platform.url}>PLAY</Button>
                            </List.Item>
                        )
                    })}
                </List>
            </div>}
        </>
    )
    
    const blocks = [
        { index: 0, content:(props)=>songBlockContent({...props}), additionalOptions: ['center']},
    ]
    
    return (
            <Page background={background} blocks={blocks} />
    )
}
}

export default withRouter(Spotify)