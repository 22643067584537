import React, { useCallback, useMemo, useState,useEffect } from "react"
import { Canvas, useFrame, useThree } from "react-three-fiber"
import { Stars, PerspectiveCamera } from "drei"
import Block from "../components/Block"
import moon1 from "../images/moon 1.png"
import planet1 from "../images/planet1.png"
import planet2 from "../images/planet 2.png"
import planet3 from "../images/planet 3.png"
const count = 100
const sep = 3

function Points() {
    const bufferRef = useRef()
    let t = 0 
    let f = 0.002
    let a = 3

    const graph =  useCallback((x, z)=>{
        return Math.sin(f * (x ** 2 + z ** 2 + t)) * a
    }, [t, f, a])

    let positions = useMemo(()=>{
        let positions = []
        for (let xi = 0; xi < count; xi++) {
            for (let zi = 0; zi < count; zi++) {
                let x = sep * ( xi - count / 2 )
                let z = sep * ( zi - count / 2 )
                let y = graph(x, z)
                positions.push(x, y, z)
            }
        }
        return new Float32Array(positions)
    }, [count, sep])

    useFrame(()=> {
        t+=15
        const positions = bufferRef.current.array
        let i = 0
        for (let xi = 0; xi < count; xi++) {
            for (let zi = 0; zi < count; zi++) {
                let x = sep * ( xi - count / 2 )
                let z = sep * ( zi - count / 2 )
                let y = graph(x, z)
                positions[i + 1] = graph(x, z)
                i += 3
            }
        }
        bufferRef.current.needsUpdate= true
    })

    return (
        
        <points>
            <bufferGeometry attach='geometry'>
                <bufferAttribute 
                    attachObject={['attributes', 'position']}
                    ref = {bufferRef}
                    array={positions}
                    count={positions.length / 3}
                    itemSize={3}
                />
            </bufferGeometry>
            <pointsMaterial attach="material"
                color={0xFFFFF}
                size={0.5}
                sizeAttenuation
                transparent={false}
                alphaTest={0.5}
                opacity={1.0}
            />
        </points>
    )
}
/**
 * 
 *useFrame(()=>{
        rotate = [deg2rad(deg++), 0, 0]
    })
 */
const deg2rad = degrees => degrees * (Math.PI / 180);
let deg = 0
let rotate=[deg2rad(deg), 0, 0]
function SceneCamera(props) {
    const { camera } = useThree();
   
    useEffect(() => {
     camera.fov = 75;
     camera.position.set([0, 0, 0]);
     camera.rotateY(80);
     camera.updateProjectionMatrix();
    }, []);
    return <PerspectiveCamera makeDefault>{props.children}</PerspectiveCamera>;
}
function Dolly({offsetY}) {
    
    // This one makes the camera move in and out
    useFrame(({ clock, camera }) => {
        camera.position.x = offsetY*.05
        camera.position.y = offsetY*.05
        camera.position.z = offsetY*.2
        camera.rotation.x=deg2rad(-35);
        camera.rotation.z=deg2rad(20);
        deg+=.01
        camera.rotation.y = deg2rad(deg)
    })
    return null
  }
export default function AnimationCanvas({offsetY}) {
    
    
    return (
        <Canvas
        style={{display:'block'}}>
                <Dolly offsetY={offsetY}/>
                <Stars radius={200} />
        </Canvas>
        )
}