import axios from "axios"
import Router from "./router"

/**
 * Singleton class for managing web request using Axios  
 */
class ApiManager {
    constructor() {
        // Setup axios instance
        this.axiosInstance = axios.create({
            timeout: 1000,
        })
        this.location="http://54.162.38.116:8080"
        // Setup router
        this.router = new Router(this.axiosInstance, this.location)
    }
}

export default new ApiManager()