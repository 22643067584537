import React from "react"
import ReactDom from "react-dom"
import App from "./App"
import "../semantic-theme/semantic.less";
import "./fontawesome/less/fontawesome.less"
import "./fontawesome/less/regular.less"
import "./fontawesome/less/solid.less"
import "./fontawesome/less/brands.less"
import "./css/App.less"
import Main from "./Main";

ReactDom.render(<Main />, document.getElementById('app'))