import image from "./images/easy4me.jpg"

const Song = () => ({
    "name": "EASY 4 ME",
    "producedBy": "UKNOWMEBC X KAYEANDRE X PRXD. JAY",
    "coverArt": image,
    "platforms": [
        {
            "name": "spotify",
            "url": "https://open.spotify.com/track/1cLASMxCsN6GoJzh3fJ7EB?si=f76c6854d8534d44"
        },
        {
            "name": "soundcloud",
            "url": "https://soundcloud.com/kayeandre/easy4me"
        },
        {
            "name": "youtube",
            "url": "https://www.youtube.com/watch?v=XUCKDDyv39s"
        },
        {
            "name": "apple music",
            "url": "https://music.apple.com/us/album/easy-4-me-single/1713095581"
        }
    ]
})

export default Song