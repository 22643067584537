import image from "./images/redemption.jpg"

const Song = () => ({
    "name": "Redemption (Demo)",
    "producedBy": "KAYEANDRE",
    "coverArt": image,
    "platforms": [
        {
            "name": "soundcloud",
            "url": "https://soundcloud.com/kayeandre/sets/redemption"
        },
    ]
})

export default Song