import React, { useCallback, useMemo, useState,useEffect } from "react"
import { withRouter } from "./withRouter"
import { Canvas, useFrame, useThree } from "react-three-fiber"
import { Button, Header, Menu, Placeholder, Transition, Image as Img, Form,List } from "semantic-ui-react"
import { OrbitControls, Stars, PerspectiveCamera, Sky } from "drei"
import Page from "./components/Page"
import Song from "./song"
import Song2 from "./song2"
import AnimationCanvas from "./components/AnimationCanvas"
import ApiManager from "./api/ApiManager"

const location = 'http://54.162.38.116:8080'
class App extends React.Component {
    
    constructor(props){
        super(props)
        console.log(props.location)
        this.state = { song: Song2(), img: false, email: '', joined: false}
    }
    componentDidMount= async()=>{
        const loadImage = (image) => {
            return new Promise((resolve, reject) => {
              const loadImg = new Image()
              loadImg.src = image
              // wait 2 seconds to simulate loading time
              loadImg.onload = () =>
                setTimeout(() => {
                  resolve(image.url)
                }, 2000)
      
              loadImg.onerror = err => reject(err)
            })
        }
        await loadImage(this.state.song.coverArt)
        .then(() => this.setState({img:true}))
        .catch(err => console.log("Failed to load images", err))
    }
    joinTheClub = async() => {
        
        if(this.state.email==='') {
            this.setState({joined: "failed"})
            return 
        }
        fbq('trackCustom', 'join_the_club', {email: this.state.email});
        let res
        if (/^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/.test(this.state.email))
        {
            res = await ApiManager.router.Subscribe.Join({email: this.state.email})
            if(res.status===200) {
                this.setState({joined: true})
            }
            else {
                this.setState({joined: "failed"})
                return
            }
        }
        else {
            this.setState({joined: "failed"})
            return
        }
        
    }
    handleChange = (e, {name, value}) => {
        this.setState({[name]:value})
    }
    
  
      
render() {
    const items = [
        { key: 'music', name: 'MUSIC' },
        { key: 'gallery', name: 'GALLERY' },
        { key: 'clubpym', name: 'CLUB PYM' }
    ]

    let menu = () => (
        <Transition animation="fade" duration={1000} transitionOnMount reactKey="menu">
        <Menu className="navBar" items={items}/>
        </Transition>
    )

    let titleBlockContent = (props) => (
        <>  
                <Header as={"h1"}>KAYEANDRE</Header>
                <Button onClick={()=>props.switchBlock(1)}>STREAM "EASY 4 ME"</Button>
                <br></br>
                <div style={{position: "absolute", bottom:"15%", display:"flex", alignItems: "center", flexDirection:"column", color: "white"}}>
                    <p>Find out more from the club?</p>
                    <Button onClick={()=>props.switchBlock(2)}>CLUB PYM</Button>
                </div>
        </>
    )

    let background = (offsetY)=>{
        let translation =  `translateY(${offsetY * 0.05}px)`
        let translation2 =  `translateY(${offsetY * 0.1}px)`
        /**
         * 
         * <div style={{position: "absolute", height: "100%", width: "100%"}}>
                <Image src={planet1} width={50} style={{position: "absolute",left: "20%", top: "10%", transform: translation}}></Image>
                <Image src={planet2} width={100} style={{position: "absolute",left: "50%", top: "20%", transform: translation2}}></Image>
                <Image src={planet3} width={150} style={{position: "absolute", left: "10%", top: "50%", transform: translation}}></Image>
                <Image src={moon1} width={50} style={{position: "absolute", left: "70%", top: "60%", transform: translation2}}></Image>
            </div>
         */
        return(
        <>
            <AnimationCanvas offsetY={offsetY}/>
        </>
    )}

    let songBlockContent = (props) => (
        <>  {(this.state.song!==undefined)&&
            <div className='newSongContent'>
                <Placeholder style={{ height: "auto", maxHeight: "300px", maxWidth: "300px"}}>
                    <Img src={this.state.song.coverArt} style={{ height: "auto", width: "100%", maxHeight: "300px", maxWidth: "300px"}}/>
                </Placeholder>
                <Header>{this.state.song.name.toUpperCase()}</Header>
                <p>PROD. BY {this.state.song.producedBy.toUpperCase()}</p>
                <List className="musicPlatforms">
                    {this.state.song.platforms.map((platform)=>{
                        return(
                            <List.Item key={platform.name}>
                                <div>{platform.name.toUpperCase()}</div>
                                <Button secondary as='a' href={platform.url}>PLAY</Button>
                            </List.Item>
                        )
                    })}
                </List>
            </div>}
        </>
    )
    
    let signUpBlockContent = (props) => (
        <>
            <div style={{width: "50%", height: "auto", background:"white", padding: "24px", minWidth: "288px"}}>
                <div className="flexcenter">
                    <Header>CLUB PYM</Header>
                    <p>All things PYM, find out what the hype is all about</p>
                <p style={{display: (this.state.joined==='failed')?'block':'none', color: "red"}}>Invalid email please try again later.</p>
                <Form.Input placeholder="Enter a valid email" name='email' value={this.state.email} onChange={this.handleChange} style={{display: (this.state.joined!==true)?'block':'none'}}></Form.Input>
                <br></br>
                <Button secondary style={{margin: "0px"}} onClick={async ()=>this.joinTheClub()} style={{display: (this.state.joined!==true)?'block':'none'}}>JOIN THE CLUB</Button>
                <p style={{display: (this.state.joined===true)?'block':'none', fontWeight: 600}}>Thanks For Joining CLUB PYM</p>
                </div>
                
            </div>
        </>
    )
    const blocks = [
        { index: 0, content:(props)=>titleBlockContent(props), additionalOptions: ['title']},
        { index: 1, content:(props)=>songBlockContent(props), additionalOptions: ['center']},
        { index: 2, content:(props)=>signUpBlockContent(props), additionalOptions: ['center']}
    ]
    
    return (
            <Page background={background} blocks={blocks} />
    )
}
}

export default withRouter(App)