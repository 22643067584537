import React from "react";
import {
  BrowserRouter as Router,
  Routes,
  Route,
} from "react-router-dom";
import App from "./App";
import Spotify from "./Spotify";

// This site has 3 pages, all of which are rendered
// dynamically in the browser (not server rendered).
//
// Although the page does not ever refresh, notice how
// React Router keeps the URL up to date as you navigate
// through the site. This preserves the browser history,
// making sure things like the back button and bookmarks
// work properly.

export default function Main() {
  return (
    <Router>
        {/*Place pages here*/}
        <div className="brands" style={{position: "absolute"}}>
                <a href="https://open.spotify.com/artist/5KMWoOycFFU0g6AL2xHITZ?autoplay=true"><i className="fab fa-spotify"></i></a>
                <a href="https://soundcloud.com/kayeandre"><i className="fab fa-soundcloud"></i></a>
                <a href="https://www.youtube.com/channel/UC5Y9DOMqoyPE5HLwhdlk6Pg"><i className="fab fa-youtube"></i></a>
                <a href="https://www.instagram.com/kayeandrepym/"><i className="fab fa-instagram"></i></a>
                <a href="https://twitter.com/kayeandrepym"><i className="fab fa-twitter"></i></a>
        </div>
        {/*
          A <Switch> looks through all its children <Route>
          elements and renders the first one whose path
          matches the current URL. Use a <Switch> any time
          you have multiple routes, but you want only one
          of them to render at a time
        */}
        <Routes>
          <Route exact path="/"element={<App />} />
          <Route exact path="/spotify"element={<Spotify />} />
        </Routes>
    </Router>
  );
}

// You can think of these components as "pages"
// in your app.

function Home() {
  return (
    <div>
      <h2>Home</h2>
    </div>
  );
}

function About() {
  return (
    <div>
      <h2>About</h2>
    </div>
  );
}

function Dashboard() {
  return (
    <div>
      <h2>Dashboard</h2>
    </div>
  );
}
