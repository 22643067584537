import React from "react"
import { Transition,Header, Ref, Visibility} from "semantic-ui-react"
export default class Block extends React.Component {
    constructor(props) {
        super(props)

        this.state = {
            continuous: false,
            log: [],
            logCount: 0,
            once: true,
            passed: ((props.title)?true:false),
            calculations: {
                topVisible: ((props.title)?true:false),
                pixelsPassed: 0
            },
        }
    }

    
    
    render() {
        const { calculations } = this.state
        let visible = this.props.visible
        return (
            <Visibility onUpdate={(e,data)=>this.props.handleUpdate(e,data,this.props.index)} style={{height: "100%", width:"100%"}}>
                <Transition visible={visible} animation='fade up' duration={500}>
                <div className={'block'+((this.props.title||this.props.center)?" title" : "")}>
                    
                    {
                        this.props.children
                    } 
                    
                </div>
                </Transition>
            </Visibility>
        )
    }
}